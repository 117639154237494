.titleTab {
  font-size: 20px;
  padding: 4px 20px 6px;
  background: #0d5fac;
  color: #fff;
  font-weight: normal;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 25px;
}
