.navmainMenu {
  font-family: "Sarabun";
  background: #fff;
  border-bottom: 1px solid #c6c6c6;
  border-radius: 0;
  border-width: 0 0 1px;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 900;
  min-height: 50px;
  margin-bottom: 20px;
}

.navbarHeader {
  width: 20%;
}

.logoWeb {
  display: block;
  float: left;
  padding: 13px 15px;
  text-decoration: blink;
  margin-right: 23px;
}
.imgLogoWeb {
  width: 90%;
  float: left;
}
.titleLogoWeb {
  margin: 0;
  color: #c61f0c;
}

.descLogoWeb {
  margin: 0 0 5px 0;
  color: #0d5fac;
}

.childMainMenu {
  display: inline-block;
  width: fit-content;
  padding: 13.5px 0px;
  font-size: 16px;
  /*
  padding: 13.5px 5px;
    font-size: 2em;
    << responsive with 998px have problem*/
}

.childMainMenu:hover {
  border-bottom: 3px solid #c61f0c;
}
.linkMainMenu {
  text-decoration: blink;
  color: #26558b;
  font-weight: normal;
  letter-spacing: 1px;
}

.linkMainMenu:hover,
.linkMainMenu:focus {
  background-color: transparent !important;
}
/*
.active {
  border-bottom: 3px solid #c61f0c;
  background-color: transparent;
}
*/

.childMainMenu.active > a {
  background-color: transparent !important;
}
.childMainMenu.active {
  border-bottom: 3px solid #c61f0c;
}
.profileMenu {
  display: block;
  float: left;
  background-color: #fff;
}
.profileDropdownMenu {
  width: 100%;
  padding: 0;
  display: block;
  float: left;
}
.width100 {
  width: 100%;
}
.navmainMenu .linkProfileMenu {
  padding: 10px 20px !important;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  color: #26558b;
}
.profileDropdownMenu:hover,
.profileDropdownMenu:focus,
.linkProfileMenu:hover,
.linkProfileMenu:focus {
  background-color: #26558b !important;
  color: #fff;
  padding: 15px 20px;
}
.navmainMenu ul.profileMenu > li > a:hover,
.navmainMenu ul.profileMenu > li > a:focus {
  background-color: #26558b !important;
}
.navmainMenu ul.mainMenu > li > a:hover,
.navmainMenu ul.mainMenu > li > a:focus {
  background-color: transparent !important;
}
.profileMenu li:hover,
.profileMenu li:hover,
.profileMenu li a:hover,
.profileMenu li a:focus,
.profileMenu .open > a,
.profileMenu .open > a:hover,
.profileMenu .open > a:focus {
  background-color: #26558b;
}
.profileMenu:hover span,
.profileMenu:focus span,
.profileMenu a:hover span,
.profileMenu a:focus span,
.profileMenu:hover i.fa,
.profileMenu:focus i.fa,
.profileMenu a:hover i.fa,
.profileMenu a:focus i.fa {
  color: #fff;
}
.dropdown.open .dropdown-toggle span,
.dropdown.open .dropdown-toggle i.fa {
  color: #fff;
}
.nameProfilePreview:hover {
  color: #fff;
}
.profileDropdownMenu.dropdown-menu > li > a:focus,
.profileDropdownMenu.dropdown-menu > li > a:hover {
  background-color: #26558b !important;
  color: #fff;
}
.profilePreview {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  background: #fff;
  float: left;
}
.imgProfilePreview {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.nameProfilePreview {
  padding: 13px 10px;
  display: block;
  float: left;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  min-width: 110px;
  color: #26558b;
}
.nameProfilePreview .fa {
  font-size: 0.5em;
  float: right;
  padding-top: 3px;
  padding-left: 15px;
}
.navmainMenu .profileMenu a.dropdown-toggle i.fa {
  float: right;
  padding: 15px 0;
}
.nameProfilePreview:hover,
.nameProfilePreview:focus {
  color: #fff;
}
.navmainMenu .profileMenu a.dropdown-toggle.active {
  background-color: #26558b;
}
nav.navmainMenu .container {
  padding: 0;
}
.logoWeb img.imgLogoWeb {
  width: 100%;
  margin-top: 5px;
  max-width: 102px;
  margin-right: 22px;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 30px;
  height: 2px;
  border-radius: 1px;
  border: 2px solid #0d5fac;
}
.navmainMenu .navbar-toggle {
  padding: 9px 10px;
  margin-right: 29px;
  margin-top: 13px;
  border: 1px solid #bfbfbf;
}

.navmainMenu .dropdown-menu > li > a > span > .nameProfilePreview {
  color: #fff;
}
.navmainMenu .nameProfilePreview:hover,
.nameProfilePreview:focus {
  color: #fff;
}
.navmainMenu .dropdown-menu > li > a {
  color: #26558b;
}
.navmainMenu .ant-menu-item-selected {
  background-color: #c61f0c;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #0d5fac;
}
.navmainMenu .btn-respon {
  background-color: transparent;
  color: #0d5fac;
  font-size: 1.4em;
  padding: 23px 5px;
  padding-left: 5px;
  display: none;
}
.navmainMenu .dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #fff;
}
.navmainMenu .btn:hover,
.navmainMenu .btn:focus,
.navmainMenu .btn.focus {
  color: #0d5fac;
  text-decoration: none;
}
.navmainMenu .btn:focus,
.navmainMenu .btn.focus {
  color: #0d5fac;
  text-decoration: none;
}

@media (max-width: 1195px) {
  .navmainMenu .nav > li > a {
    padding: 15px 10px;
  }
}
@media (max-width: 991px) {
  .navmainMenu .nav > li.childMainMenu {
    /* width:100%; */
  }
  .navmainMenu .childMainMenu {
    padding: 24.5px 10px;
  }
  .navmainMenu .nav > li > a {
    padding: 5px;
  }
  .navmainMenu .btn-respon {
    display: block;
  }
  .navmainMenu .dropdown-respon {
    margin: -11px -50px 0;
    padding-top: 0px;
  }
  .navmainMenu .profilePreview {
    margin-left: 8px;
    margin-top: 11px;
  }
}
.navmainMenu .profilePreview.navbar-respon {
  display: none;
}
@media (max-width: 991px) {
  .navmainMenu .navbar-right {
    display: none;
  }
  .navmainMenu .profilePreview.navbar-respon {
    display: flex;
  }
  @media (max-width: 767px) {
    .logoWeb img.imgLogoWeb {
      margin-left: 15px;
    }
    .navmainMenu .profilePreview.navbar-respon {
      display: none;
    }
    .navmainMenu .profileMenu a.dropdown-toggle i.fa {
      float: right;
      padding: 23px 0;
    }
    .nameProfilePreview {
      padding: 20px 10px;
    }
    .navmainMenu .navbar-nav {
      margin: 0px 0px;
    }
    .navmainMenu .dropdown-respon {
      margin: 0px 0px;
    }
    .navmainMenu .nav > li.childMainMenu {
      width: 100%;
    }
    .childMainMenu {
      padding: 13.5px 23px;
    }
    .navmainMenu .profilePreview {
      margin-left: 20px;
    }
    .navmainMenu .navbar-nav .open .dropdown-menu {
      background-color: #fff;
    }
    .navmainMenu .navbar-nav .open .dropdown-menu > li > a {
      margin-left: 0px;
    }
  }
  .mainMenu li {
    border-bottom: 3px solid rgba(0, 0, 0, 0);
  }
}
@media (max-width: 765px) {
  .navmainMenu .navbar-right {
    display: block;
  }
  .navmainMenu .profilePreview {
    margin-left: 0px;
  }
  .navmainMenu .btn-respon {
    display: none;
  }
  .navmainMenu .profileMenu:hover {
    background-color: #fff;
    color: #26558b;
  }
  /* .navmainMenu .nameProfilePreview:hover, .nameProfilePreview:focus {
    color: #26558b;
} */
  .navmainMenu .profileMenu {
    width: 100%;
    display: inline-block;
  }
  .navmainMenu ul.profileMenu > li > a {
    width: 100%;
    padding: 15px;
  }
}
.navmainMenu .navbar-right {
  margin: 0;
}
.navmainMenu .nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  background-color: #26558b;
}
.navmainMenu .profileMenu a.dropdown-toggle {
  display: inline-block;
  float: right;
}
.navmainMenu .profileMenu .open a.dropdown-toggle {
  background-color: #26558b;
}
.profileMenu ul.dropdown-menu {
  padding: 0;
}
.navmainMenu .menuProfile.active {
  color: #fff;
  background-color: #26558b;
  border-top: 1px solid #fff;
}
.navmainMenu .nameProfilePreview.active {
  color: #fff;
}

.navmainMenu .nav .navbar-nav .navbar-right .profileMenu .active {
  background-color: #26558b;
}
.navmainMenu .dropdown.active {
  background-color: #26558b;
}
.navmainMenu .profileMenu a.dropdown-toggle i.fa.active {
  color: #fff;
}
@media (max-width: 1199px) {
  .childMainMenu {
    padding: 13.5px 0px;
  }
  .nameProfilePreview {
    font-size: 1em;
  }
}
