/* Footer */
.footer{
  width: 100%;
  background: #0d5fac;
  text-align: center;
  color: #fff;
}

.container {
  padding: 20px;
}
/* END Footer */