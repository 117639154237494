.mqa .cardBox {
  border: 1px solid #5d91cc;
  margin-top: 0px;
  margin-bottom: 16px;
  text-align: left;
  background-color: #fff;
}
.mqa .itemCardBox {
  padding: 0px 0px 0px 0px;
}
.mqa .box-name {
  width: 80%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-top: 8px;
}
.mqa .box-name:hover,
.mqa .box-name:focus {
  text-decoration: none;
}

.mqa .faFileDownload {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 2px;
}
.mqa .faFileUpload {
  color: white;
  float: right;
}
.mqa a {
  color: #48b0f2;
}
.mqa .faExternalLink {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 2px;
}
.bodyContent .mqa .alert {
  font-size: 1em !important;
}
.mqa .textTitleLarge {
  width: 100%;
  border-bottom: 1px solid #26558b;
  margin-bottom: 15px;
  color: #26558b;
  font-weight: normal;
  font-size: 1.1em;
}
.mqa .textColor {
  color: #26558b;
}
.mqa .trHeaderColor {
  background-color: #5d91cc;
  color: white;
}
.mqa .btnUpload {
  background-color: #26558b;
  border-color: #2e6da4;
  font-size: 1em;
  margin-top: 5px;
}
.mqa .inputUpload {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}

.mqa .ant-btn:focus,
.ant-btn:hover {
  color: #fff;
  background-color: #26558b;
}
.mqa .ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  color: #fff;
  background-color: #26558b;
}
.mqa .ant-btn:hover {
  background: #26558b;
}

.mqa font {
  font-family: "Sarabun";
}
.mqa p {
  margin: 0px;
}

.radioStyle div {
  overflow: hidden;
}

.radioStyle label {
  width: 100%;
  font-weight: normal;
  color: #000;
  line-height: 1.3em;
}
.radioStyle input[type="radio"]:empty,
.radioStyle input[type="checkbox"]:empty {
  display: none;
}

.radioStyle input[type="radio"]:empty ~ label,
.radioStyle input[type="checkbox"]:empty ~ label {
  position: relative;
  text-indent: 1.5em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioStyle input[type="radio"]:empty ~ label:before,
.radioStyle input[type="checkbox"]:empty ~ label:before {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  content: "";
  width: 1em;
  height: 1em;
  background: #c6c6c6;
  border: 1px solid #48b0f2;
  border-radius: 3px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.4);
}

.radioStyle input[type="radio"]:disabled ~ label::before,
.radioStyle input[type="radio"]:disabled ~ label {
  cursor: not-allowed;
}
.radioStyle input[type="radio"]:hover:not(:checked) ~ label,
.radioStyle input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #000;
}

.radioStyle input[type="radio"]:hover:not(:checked) ~ label:before,
.radioStyle input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: "\2714";
  text-indent: 0em;
  color: #e5e5e5;
  padding-left: 1px;
}

.radioStyle input[type="radio"]:checked ~ label:before,
.radioStyle input[type="checkbox"]:checked ~ label:before {
  content: "\2714";
  text-indent: 0em;
  padding-left: 1px;
  color: #fff;
  background-color: #ccc;
}

.radioStyle input[type="radio"]:focus ~ label:before,
.radioStyle input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.radioStyle-default input[type="radio"]:checked ~ label:before,
.radioStyle-default input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #1890ff;
}
