.test {
  margin-top: 5px;
}

.menuTabs {
  border-bottom: 1px solid #48b0f2;
}
.bodyContent .ant-tabs-content .ant-tabs-tabpane .alert {
  font-size: 1em;
}
.site-application-status .menutabs-child a,
.site-application-status .menutabs-child.active a,
.site-application-status .menutabs-child:hover a,
.site-application-status .menutabs-child a:hover,
.site-application-status .menutabs-child:focus a,
.site-application-status .menutabs-child a:focus,
.site-member-contract .menutabs-child a,
.site-member-contract .menutabs-child.active a,
.site-member-contract .menutabs-child:hover a,
.site-member-contract .menutabs-child a:hover,
.site-member-contract .menutabs-child:focus a,
.site-member-contract .menutabs-child a:focus {
  color: #26558b !important;
}
.site-application-status .menutabs-child.active,
.site-member-contract .menutabs-child.active {
  border-bottom: 3px solid #bb1014;
  font-weight: normal;
  color: #26558b;
}

.site-application-status .menuTabs > li > .menutabs-link,
.site-member-contract .menuTabs > li > .menutabs-link {
  background-color: transparent !important;
}

/*
.menuTabs>.menuTabsActive>a:hover{
	background-color: transparent;
}
.menuTabs>.menuTabsNonActive>a:hover{
	background-color: transparent;
} */

.ulModal {
  padding-left: 0px;
  list-style-type: none;
}
td {
  vertical-align: top;
}
.title {
  background-color: #0d5fac;
  padding: 7px 5px 10px 20px;
  color: #ffffff;
}
.messages {
  padding-left: 15px;
}

.buttonDownload {
  background-color: #ffffff;
  padding: 5px 10px 5px 10px;
  border: 1px solid #48b0f2;
  color: #48b0f2;
  border-radius: 10px;
  width: 200px;
}
.colorRed {
  color: red;
}
.colorGreen {
  color: #65d378;
}

.buttonSubmit {
  margin-top: 20px;
  background-color: #48b0f2;
  padding: 5px 10px 5px 10px;
  border: 1px solid #48b0f2;
  color: #ffffff;
  border-radius: 10px;
  width: 200px;
}
.with100 {
  width: 100%;
}
.applicationModal .ant-col-6 {
  margin-bottom: 10px;
  width: 33%;
}

.applicationModal .ant-modal-header {
  background-color: #0d5fac;
}

.tableThColor {
  background-color: #5d91cc;
  color: #ffffff;
}
.tableThColor th {
  text-align: center;
}
.table-application-status tbody > tr > td {
  text-align: -webkit-center;
}

.applicationModal .ant-modal-title {
  margin: 0;

  line-height: 22px;
  font-weight: normal;
  color: #fff;
}

.applicationModal .ant-modal-content {
  font-family: "Sarabun";
}
.applicationModal .ant-modal-body {
  font-size: 1em;
  line-height: 1.6;
  font-weight: normal;
  padding: 0 40px;
}
.applicationModal a {
  color: #49b0f2;
  font-size: 1em;
  font-weight: normal;
}
.applicationModal a i.fa-download {
  font-size: 12px;
  padding-right: 5px;
}
.applicationModal .listFile {
  width: 80%;
  overflow-x: auto;
  background-color: #e5e5e5;
  margin-left: 45px;
  margin-right: 10px;
  height: 100px;
}
.applicationModal .textTitleLarge {
  width: 100%;
  border-bottom: 1px solid #26558b;
  margin-bottom: 15px;
  font-size: 1.1em;
  color: #26558b;
  font-weight: normal;
  letter-spacing: normal;
}
.applicationModal .cardBox {
  border: 1px solid #5d91cc;
  margin-top: 0px;
  margin-bottom: 16px;
  text-align: left;
}
.applicationModal .itemCardBox {
  padding: 5px 0px 5px 0px;
  font-size: 1em;
}
.applicationModal .cardBox a:hover,
.applicationModal .cardBox a:focus {
  text-decoration: none;
}
.applicationModal .faFileText {
  color: #f36d88;
  float: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 7px;
  font-size: 1em;
}
.applicationModal p {
  text-align: justify;
  line-height: 1.3em;
}
.applicationModal
  .ant-modal-close
  i.anticon.anticon-close.ant-modal-close-icon {
  background: #fff;
  border-radius: 50px;
  padding: 4px;
  font-size: 11px;
  color: #1890ff;
}

label.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  background-color: #d9d9d9;
  border: 1px solid #afd9f6;
}
label.ant-checkbox-wrapper-checked .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
}
.block-roles {
  margin: 15px 0;
}

.ant-modal-footer {
  display: none;
}
.block-application-roles,
.block-application-form,
.block-document-file {
  margin: 5px 0;
  width: 100%;
  display: inline-block;
}
.block-btn-footer {
  margin: 5px auto;
  width: fit-content;
  display: block;
}
.block-memberapplication-form {
  display: inline-block;
  width: 100%;
  margin: 5px 0 25px 0;
}
.block-memberapplication-form .alert.alert-danger {
  padding: 10px;
}
.upload-document-file .form-group {
  margin-bottom: 7px;
}
.block-application-form .input-file .btn-upload,
.upload-document-file .input-file .btn-upload {
  background-color: #47aff2;
  border: 1px solid #47aff2;
  color: #fff;
  font-size: 14px;
  width: 100%;
  padding: 9px 5px 8px 5px;
}
.block-application-form .input-file .btn-upload-file,
.upload-document-file .input-file .btn-upload-file {
  display: none;
}
.block-application-form .input-file .input-upload-file,
.upload-document-file .input-file .input-upload-file {
  background: #fff;

  padding: 5px 15px;
  height: 39px;
}
.block-application-form .input-file .btn-upload:hover,
.block-application-form .input-file .btn-upload:focus,
.upload-document-file .input-file .btn-upload:hover,
.upload-document-file .input-file .btn-upload:focus {
  color: #fff;
}
.upload-document-file .list-file {
  background: #e5e5e5;
  padding: 0 15px;
  max-height: 180px;
  overflow: auto;
}
.upload-document-file i.fa-close {
  color: #c61f0c;
}
.upload-document-file .list-file button {
  border: 0;
  background: none;
}
.block-btn-footer .btn-reset {
  background: #f6f6f6;
  border: 2px solid #85aed5;
  border-radius: 4px;
  padding: 1px 25px;
  margin: 0 10px;
  height: 40px;
  color: #0d5fac;
}
.block-btn-footer .btn-submit {
  font-weight: normal;
  background: #0d5fac;
  border: 2px solid #0d5fac;
  border-radius: 4px;
  padding: 1px 25px;
  margin: 0 10px;
  height: 40px;
  color: #fff;
}
.btn-review {
  background: #48b0f2;
  color: #fff;
  border: 1px solid #48b0f2;
  border-radius: 3px;
  padding: 0 15px;
}
.btn-review .fa {
  padding-right: 5px;
}
.rolesRpItems {
  line-height: 1em;
}
.modal-view-application {
  display: inline-block;
  margin: 20px 0px;

  width: 100%;
}
.modal-view-application table {
  width: 100%;
}

.btn-cancel-request {
  background: #ff5e4b;
  color: #fff;
  border: 1px solid #ff5e4b;
  border-radius: 3px;
  padding: 0 20px;
  height: 35px;
  margin: 10px 0;
}

.applicationModal table > tbody > tr > td > p {
  text-align: left;
  line-height: 1.2em;
}
.cancelRequestModal .ant-modal-title {
  margin: 0;

  line-height: 22px;
  font-weight: normal;
  color: #fff;
}
.cancelRequestModal .ant-modal-header {
  background-color: #ff5e4b;
}
.cancelRequestModal
  .ant-modal-close
  i.anticon.anticon-close.ant-modal-close-icon {
  background: #fff;
  border-radius: 50px;
  padding: 4px;
  font-size: 11px;
  color: #ff5e4b;
}
.cancelRequestModal .ant-modal-content {
  font-family: "Sarabun";
}

.cancelRequestModal .ant-modal-body {
  line-height: 1.6;
  font-weight: normal;
  padding: 0 40px;
}
.cancelRequestModal .modal-cancel-request {
  padding: 0 15px;
  display: inline-block;
  width: 100%;
}
.cancelRequestModal h4 {
  font-weight: bold;
}
.cancelRequestModal p {
  font-weight: normal;
  margin-bottom: 0;
}

.cancelRequestModal .block-btn-footer {
  margin: 20px auto;
}
.cancelRequestModal .block-btn-footer .btn-keeep {
  background: #f6f6f6;
  color: #ff5e4b;
  border: 1px solid #ff5e4b;
  border-radius: 3px;
  padding: 0 20px;
  font-weight: normal;
  margin: 0 10px;
  height: 35px;
}

.cancelRequestModal .block-btn-footer .btn-cancel {
  background: #ff5e4b;
  color: #fff;
  border: 1px solid #ff5e4b;
  border-radius: 3px;
  padding: 0 20px;
  font-weight: normal;
  margin: 0 10px;
  height: 35px;

  color: #fff;
}
.site-application-status .table-striped > tbody > tr:nth-of-type(even),
.site-member-contract .table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f9f9f9;
}
.site-application-status .table-striped > tbody > tr:nth-of-type(odd),
.site-member-contract .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}
.site-application-status .table-striped > thead > tr > th,
.site-member-contract .table-striped > thead > tr > th {
  border: 0;
}
.btn-upload-contract {
  background: #48b0f2;
  color: #fff;
  border: 1px solid #48b0f2;
  border-radius: 3px;
  padding: 5px 15px;
  margin-bottom: 20px;
}
.memberContractModal .ant-modal-header,
.viewPayslipModal .ant-modal-header {
  background-color: #0d5fac;
}

.memberContractModal .ant-modal-title,
.viewPayslipModal .ant-modal-title {
  margin: 0;

  line-height: 22px;
  font-weight: normal;
  color: #fff;
}

.memberContractModal .ant-modal-content,
.viewPayslipModal .ant-modal-content {
  font-family: "Sarabun";
}

.memberContractModal .ant-modal-body,
.viewPayslipModal .ant-modal-body {
  font-size: 1em;
  line-height: 1.6;
  font-weight: normal;
  padding: 0 40px;
}
.memberContractModal .block-btn-footer {
  margin: 20px auto;
}
.memberContractModal .modal-member-contract {
  display: inline-block;
}
.viewPayslipModal .modal-view-payslip {
  margin: 15px 15px 25px 15px;
  display: inline-block;
  width: 100%;
}
.viewPayslipModal .modal-view-payslip table {
  width: 100%;
}
.viewPayslipModal .modal-view-payslip table > tbody > tr {
  height: 35px;
}
.width-160 {
  width: 160px;
}
.width-60 {
  width: 60px;
}
.width-120 {
  width: 120px;
}

.memberContractModal
  .ant-modal-close
  i.anticon.anticon-close.ant-modal-close-icon,
.viewPayslipModal
  .ant-modal-close
  i.anticon.anticon-close.ant-modal-close-icon {
  background: #fff;
  border-radius: 50px;
  padding: 4px;
  font-size: 11px;
  color: #1890ff;
}
.site-application-status table > tbody > tr > td,
.site-member-contract table > tbody > tr > td {
  vertical-align: middle;
  font-size: 1em;
}
.site-application-status table > thead > tr > th {
  vertical-align: middle;
  font-size: 1em;
}
.site-application-status .tableWidth .fa,
.site-member-contract .tableWidth .fa {
  font-size: 1em;
  margin: 0px 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.site-application-status .tableWidth .buttonSubmit .fa,
.site-member-contract .tableWidth .fa {
  display: unset;
  margin-left: 0px;
}
.site-application-status .tableWidth .buttonSubmit,
.site-member-contract .tableWidth .buttonSubmit {
  margin-top: 0px;
  padding: 3px 10px 5px 10px;
  border-radius: 6px;
  width: 85px;
  font-size: 1em;
  vertical-align: middle;
}
.block-btn-footer button[disabled] {
  background: rgba(0, 0, 0, 0.2);
  border: 2px solid #c6c6c6;
}

.applicationModal .btn {
  font-size: 1em;
  font-weight: normal;
  letter-spacing: 1px;
}
.applicationModal b {
  color: #26558b;
}
.ant-modal-wrap .ant-modal {
  padding-top: 75px;
}
.cancelRequestModal .form-control {
}
.cancelRequestModal h4 {
  padding-top: 5px;
}
.box-width100 {
  width: 100%;
}
.site-application-status table > tbody > tr > td,
.site-member-contract table > tbody > tr > td {
  cursor: pointer;
}

.status-text {
  display: block;
}

.status-text::first-letter {
  text-transform: uppercase;
}

td {
  word-break: break-all;
}

.site-member-contract .tableWidth .buttonSubmit {
  margin-top: 0px;
  border-radius: 6px;
  width: 85px;
  vertical-align: middle;
}
.site-application-status table .table > thead > tr > th,
.site-application-status table .table > tbody > tr > th,
.site-application-status table .table > tfoot > tr > th,
.site-application-status table .table > thead > tr > td,
.site-application-status table .table > tbody > tr > td,
.site-application-status table .table > tfoot > tr > td {
  padding: 6px !important;
}

.list-none {
  list-style-type: none;
}
.p-5 {
  padding: 5px;
}
.ulModal li {
  margin-bottom: 5px;
}
.ant-modal-body td {
  padding: 5px 0px;
}
.bodyContent .ant-checkbox-wrapper + span,
.ant-checkbox + span {
  font-weight: normal;
}
