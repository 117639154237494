.siteLogin {
  font-family: "Sarabun";
  width: 100%;

  display: block;
  float: left;

  color: #26558b;
  min-height: 100vh;
  font-size: 1em;
  font-weight: normal;

  background: rgba(13, 95, 172, 1);
  background: -moz-linear-gradient(
    top,
    rgba(13, 95, 172, 1) 0%,
    rgba(38, 85, 139, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(13, 95, 172, 1)),
    color-stop(100%, rgba(38, 85, 139, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(13, 95, 172, 1) 0%,
    rgba(38, 85, 139, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(13, 95, 172, 1) 0%,
    rgba(38, 85, 139, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(13, 95, 172, 1) 0%,
    rgba(38, 85, 139, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(13, 95, 172, 1) 0%,
    rgba(38, 85, 139, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d5fac', endColorstr='#26558b', GradientType=0 );
}
.blockLogin {
  width: 90%;
  max-width: 500px;
  margin: 7% auto;
  background: #fff;
  padding: 10px 5%;
  border-radius: 10px;
}
.logo {
  margin: 0 auto;
  display: block;
  padding: 40px 0;
  width: 70%;
}
.login .desc {
  font-size: 1.5em;
}
.inputUsername {
  /*width: fit-content;*/
  position: absolute;
  padding: 6px 16px;
  border-right: 1px solid #cbcbcb;
  height: 33px;
  width: 37px;
}
.forgetPassword {
  font-family: "Sarabun";
  width: 100%;
  text-align: right;
  display: block;
  color: #48b0f2;
  font-weight: normal;
}
.forgetPassword:hover,
.forgetPassword:focus {
  color: #48b0f2;
  text-decoration: none;
}
.btn.buttonLogin {
  background: #c61f0c;
  border: 1px solid #c61f0c;
  border-radius: 50px;
  width: 100%;
  padding: 4px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.2em;
  letter-spacing: 1px;
  color: #fff;
  height: 50px;
}
.buttonLogin:hover,
.buttonLogin:focus {
  color: #fff;
}
.inputField {
  background: #e5e5e5;
  padding-left: 40px;
}
.login label {
  font-weight: normal;
  color: #26558b;
  font-size: 1em;
}
.login input[type="text"],
.login input[type="password"],
.login input[type="number"],
.login input[type="email"] {
  -webkit-appearance: none;
  padding-left: 50px;
  background-color: #e5e5e5;
  font-weight: normal;
}
.login .label {
  font-weight: normal;
}
.login .btn {
  border-radius: 30px;
  margin-bottom: 30px;
}
.login .btn:hover,
.login .btn:focus {
  color: #fff;
}
.squaredThree {
  position: relative;
  float: left;
}

.squaredThree label {
  width: 20px;
  height: 15px;
  cursor: pointer;
  position: absolute;
  margin-top: 8px;
  left: 0;
  background: #e5e5e5;
  border: 1px solid #48b0f2;
  border-radius: 3px;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.5),
    0px 1px 0px rgba(255, 255, 255, 0.4);
}

.squaredThree label:after {
  content: "  ";
  width: 9px;
  height: 5px;
  position: absolute;
  top: 4px;
  left: 2px;
  border: 3px solid #48b0f2;
  border-top: none;
  border-right: none;
  background: transparent;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.squaredThree label:hover::after {
  opacity: 0.3;
}

.squaredThree input[type="checkbox"] {
  visibility: hidden;
}

.squaredThree input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.label-text {
  margin-left: 10px;
  margin-top: 4px;
}
.blockLogin .form-control {
  border: 1px solid #e5e5e5;
  box-shadow: unset;
}
.blockLogin form {
  width: 85%;
  margin-left: 28px;
}
