@font-face {
  font-family: "thsarabunnew";
  src: url("THSarabunNew.eot");
  src: url("THSarabunNew.eot?#iefix") format("embedded-opentype"),
    url("THSarabunNew.woff2") format("woff2"),
    url("THSarabunNew.woff") format("woff"),
    url("THSarabunNew.ttf") format("truetype"),
    url("THSarabunNew.svg#THSarabunNew") format("svg");
  font-weight: 500;
  font-style: normal;
  font-size: 1.5em;
  letter-spacing: 1px;
}
@import url("https://fonts.googleapis.com/css?family=Sarabun:300,400,700&subset=thai");
