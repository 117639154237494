.dd-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  width: 300px;
}
.dd-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 38px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  cursor: default;
  position: relative;
  background-color: #fff;
}
.dd-header span {
  margin-right: 20px;
}
.dd-header-title {
  font-weight: 300;
  margin: 2px 20px;
  margin-right: 30px;
}
.angle-down {
  color: #000;
  margin-right: 20px;
}
.dd-list {
  z-index: 10;
  position: absolute;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-top: none;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px -1px #e8e8e8;
  box-shadow: 0 2px 5px -1px #e8e8e8;
  font-weight: normal;
  padding: 15px 0;
  /* max-height: 215px; */
  /* overflow-y: scroll; */
  -webkit-overflow-scrolling: touch;
}
.dd-list .fa {
  float: right;
  color: #48b0f2;
}
.dd-list .dd-list-item:hover .fa {
  color: #fff;
}
.dd-list-item {
  width: 100%;
  font-size: 1.5rem;
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: normal;
}
.dd-list-item.selected {
  color: #fff;
  background-color: #48b0f2;
}
.dd-list-item:hover {
  color: #fff;
  background-color: #48b0f2;
}
.dd-wrapper-single {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  width: 265px;
}
.dd-wrapper-single .dd-header {
  border: 1px solid #ccc;
}
.dd-wrapper-single .dd-header .dd-header-name {
  font-weight: 400;
}
.dd-wrapper-single .dd-list {
  border: 1px solid #ccc;
  border-top: none;
}
.dd-list-body {
  height: 170px;
  overflow: auto;
}
