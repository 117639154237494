.contactPage {
  font-size: 18px;
}
.contactPage .form-control {
  font-size: 18px;
}
.contacthead {
  background-color: #26558b;
  color: #fff;
  font-size: 20px;
  padding: 10px 10px;
  margin-bottom: 20px;
}
.contact_map {
  margin: 10px 0px;
  border: 2px solid #fff;
  width: 95%;
}
.detailcontact {
  margin: 10px 0px;
}
.detail_head {
  text-align: left;
  color: #26558b;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 10px;
}
.detail_message {
  text-align: left;
  color: #4d505d;
  width: 100%;
  word-break: break-word;
}
.leftside {
  padding: 20px 5px;
}
.rightside {
  padding: 20px 5px;
}
.contact_head_add {
  text-align: left;
  color: #11385b;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
}
.contact_message_add {
  text-align: left;
  color: #4d505d;
}
.detail_head_add {
  text-align: left;
  color: #26558b;
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.btn.btn-info.contactPage {
  float: left;
  font-size: 1em;
  padding: 5px 30px;
  font-weight: bold;
}
.form-control.topic-style {
  color: #49b0f2;
}
.detail_message_add input,
.detail_message_add textarea,
.detail_message_add select {
  text-align: left;
  width: 100%;
  border: 1px solid #bbb;
  border-radius: 5px;
  background-color: #fff;
  font-size: 0.8em;
}
.mapouter {
  text-align: right;
  height: 300px;
  width: 100%;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
  border: 0px solid #fff;
}
