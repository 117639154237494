.memberProfileTabs {
  width: 100%;
}
.basicTabs,
.contactTabs,
.rolesTabs {
  width: 100%;
}
.headerTabs {
  background: #5d91cc;
  color: #fff;
  padding: 15px 20px;
  display: block;
  float: left;
  margin: 0;
  width: 100%;
  font-size: 1.2em;
  font-weight: normal;
  letter-spacing: normal;
}
.descTabs {
  display: block;
  float: left;
  background: #fff;
  padding: 15px 20px;
  width: 100%;
}
.lineTabs {
  padding: 5px 0;
  width: 100%;
  float: left;
}
.topicTabs {
  width: 30%;
  float: left;
  font-weight: bold;
}
.dataTabs {
  width: 70%;
  float: left;
  color: #7b7e8f;
}
.line {
  border-top: 1px solid #b4b6bf;
  width: 100%;
  float: left;
  margin: 10px 0;
}
.role-icon {
  font-size: 0.86em;
}
