.res .cardBox {
  border: 1px solid #5d91cc;
  margin-top: 0px;
  margin-bottom: 16px;
  text-align: left;
  background-color: #fff;
}
.res .itemCardBox {
  padding: 8px 0px 0px 0px;
}
.res .faFileText {
  color: #f36d88;
  float: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  font-size: 1.2em;
}
.res .faFileDownload {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 5px;
  font-size: 1.2em;
}
.res a {
  color: #26558b;
}
.res .faExternalLink {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 4px;
  font-size: 1em;
}
.res .box-name {
  width: 63%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #48b0f1;
}
