.abouthead{
  background-color: #26558B;
  color:#fff;
  font-size: 1.4em;
  padding: 10px 10px;
  margin-bottom: 20px;
}
.aboutimage img{
  width: 100%;
  height: auto;
}
.aboutimage{
  overflow: hidden;
  width:100%;
  min-height: 400px;
}
.aboutimage{
  margin: 20px 0px;
}
.abouttext{
  margin: 20px 0px;
  color: #4d505d;
  text-align: left;
}
