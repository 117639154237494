.bodyContent {
  margin-top: 5em;
  background-color: #f7f7f7;
  height: 100%;
}

html {
  position: relative;
  min-height: 100%;
  background-color: #f7f7f7;
}
html {
  position: relative;
  min-height: 100%;
  height: auto !important;
}
body {
  height: auto !important;
  font-family: "Sarabun" !important;
  letter-spacing: 0.5px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f5f5f5;
}
/*
body > .container {
  padding: 60px 15px 0;
}
*/
.container .text-muted {
  margin: 20px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}
.footer > .container span {
  font-size: 1em;
}
.site-content .alert {
  margin-left: 15px;
}
.site-content .ant-tabs {
  color: rgba(0, 0, 0, 1);
}

.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated,
.ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
.ant-tabs-top .ant-tabs-ink-bar-animated,
.ant-tabs-bottom .ant-tabs-ink-bar-animated {
  transition: unset !important;
}
.error-template {
  padding: 10px 51px;
  color: #f00;
}
