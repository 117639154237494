.sit .cardBox {
  list-style-type: none;
  border: 1px solid #5d91cc;
  margin-bottom: 40px;
  padding: 0px 15px;
  background: #fff;
  margin-top: 15px;
}
.sit .itemCardBox {
  padding: 5px 0px 5px 0px;
  font-size: 1em;
}
.sit .cardBox a:hover,
.sit .cardBox a:focus {
  text-decoration: none;
}

.sit .faFileDownload {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 2px;
  font-size: 1em;
}
.sit .faFileUpload {
  color: white;
  float: right;
}
.sit a {
  color: #26558b;
}
.sit .linka {
  color: #000;
}
.sit .faExternalLink {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 7px;
  font-size: 1.2em;
}
.sit .textTitle {
  width: 100%;
  border-bottom: 1px solid #26558b;
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 1.1em;
  color: #26558b;
  font-weight: normal;
}
.sit .textTitleLarge {
  width: 100%;
  border-bottom: 1px solid #26558b;
  margin-bottom: 15px;
  font-size: 1.1em;
  color: #26558b;
  font-weight: normal;
  letter-spacing: 0.8px;
}
.sit .textColor {
  color: #5d91cc;
}

.sit .trHeaderColor {
  background-color: #5d91cc;
  color: white;
}
.sit .btnUpload {
  background-color: #26558b;
  border-color: #2e6da4;
}
.sit .inputUpload {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}

.sit a {
  color: #48b0f2;
}

.sit .linkI i {
  margin-right: 15px;
}
.sit .btn {
  font-size: 1em;
  margin: 1em 0px;
  padding: 12px;
}

.sit p {
  margin: 0px;
}

.width-150 {
  width: 150px;
}
.width-200 {
  width: 200px;
}
.width-57 {
  width: 57px;
}
.width-161 {
  width: 161px;
}
.width-72 {
  width: 72px;
}
.width-65 {
  width: 65px;
}
.width-109 {
  width: 109px;
}
.min-table-820 {
  /* min-width: 820px; */
}
.width-15p {
  width: 15%;
}
.width-20p {
  width: 20%;
}
.mt-20 {
  margin-top: 20px;
}
.white-space-nowarp {
  white-space: nowrap !important;
}
