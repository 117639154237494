.bodyContent {
  padding-top: 30px;
  font-size: 14px;
  padding-bottom: 5em;
  line-height: 2;
}

.bodyContent .col-xs-1,
.bodyContent .col-sm-1,
.bodyContent .col-md-1,
.bodyContent .col-lg-1,
.bodyContent .col-xs-2,
.bodyContent .col-sm-2,
.bodyContent .col-md-2,
.bodyContent .col-lg-2,
.bodyContent .col-xs-3,
.bodyContent .col-sm-3,
.bodyContent .col-md-3,
.bodyContent .col-lg-3,
.bodyContent .col-xs-4,
.bodyContent .col-sm-4,
.bodyContent .col-md-4,
.bodyContent .col-lg-4,
.bodyContent .col-xs-5,
.bodyContent .col-sm-5,
.bodyContent .col-md-5,
.bodyContent .col-lg-5,
.bodyContent .col-xs-6,
.bodyContent .col-sm-6,
.bodyContent .col-md-6,
.bodyContent .col-lg-6,
.bodyContent .col-xs-7,
.bodyContent .col-sm-7,
.bodyContent .col-md-7,
.bodyContent .col-lg-7,
.bodyContent .col-xs-8,
.bodyContent .col-sm-8,
.bodyContent .col-md-8,
.bodyContent.col-lg-8,
.bodyContent .col-xs-9,
.bodyContent .col-sm-9,
.bodyContent .col-md-9,
.bodyContent .col-lg-9,
.bodyContent .col-xs-10,
.bodyContent .col-sm-10,
.bodyContent .col-md-10,
.bodyContent .col-lg-10,
.bodyContent .col-xs-11,
.bodyContent .col-sm-11,
.bodyContent .col-md-11,
.bodyContent .col-lg-11,
.bodyContent .col-xs-12,
.bodyContent .col-sm-12,
.bodyContent .col-md-12,
.bodyContent .col-lg-12 {
  padding-right: 0px;
  padding-left: 0px;
}
.bodyContent .ant-tabs {
  color: rgba(0, 0, 0, 1);
  color: #4d505d;
  line-height: 2;
}
.bodyContent {
  color: #4d505d;
}
.bodyContent .ant-tabs .ant-tabs-bar .ant-tabs-nav-container {
  font-size: 1em;
  font-weight: normal;
}

.bodyContent .ant-tabs .ant-tabs-nav {
  font-size: 14px;
  font-weight: normal;
}

.bodyContent table > tbody > tr > td {
  vertical-align: middle;
  white-space: pre;
}
.bodyContent table > thead > tr > th {
  vertical-align: middle;
  font-weight: normal;
  font-size: 1em;
  padding-top: 7px;
  padding-bottom: 7px;
}
.bodyContent .sit table > thead > tr > th {
  vertical-align: middle;
  font-weight: normal;
  font-size: 1em;
  padding-top: 7px;
  padding-bottom: 7px;
}
.bodyContent .app2 table > tbody > tr > td {
  vertical-align: middle;
  text-align: center;
  padding: 8px 0px;
}
.bodyContent .app2 table > thead > tr > th {
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  font-size: 1em;
  padding-top: 3px;
  padding-bottom: 3px;
}

.bodyContent .app2 table > thead > tr > th:first-child {
  vertical-align: middle;
  text-align: center;
  font-weight: normal;
  font-size: 1em;
  padding-top: 3px;
  padding-bottom: 3px;
}
.bodyContent .textTitle {
  width: 100%;
  border-bottom: 1px solid #26558b;
  margin-bottom: 15px;
  margin-top: 25px;
  font-size: 1.1em;
  color: #26558b;
  font-weight: bold;
}
.bodyContent .tableWidth,
.bodyContent table {
  overflow: auto;
}
.bodyContent .app {
  overflow: auto;
}
.bodyContent .rolesRpItems,
.bodyContent .rolesIdpItems,
.bodyContent .rolesAsItems,
.bodyContent .rolesProxyItems,
.bodyContent .roles2tierRpItems,
.bodyContent .roles2tierIdpItems,
.bodyContent .roles2tierAsItems,
.modalStatus .rolesRpItems,
.modalStatus .rolesIdpItems,
.modalStatus .rolesAsItems,
.modalStatus .rolesProxyItems,
.modalStatus .roles2tierRpItems,
.modalStatus .roles2tierIdpItems,
.modalStatus .roles2tierAsItems,
.rolesItems {
  height: 20px;
  line-height: 1em;
  font-weight: normal;
  width: auto;
  padding-left: 5px;
  margin-right: 5px;
  margin-left: 0px;
  font-size: 20px;
}
.bodyContent .rolesRpItems i,
.bodyContent .rolesIdpItems i,
.bodyContent .rolesAsItems i,
.bodyContent .rolesProxyItems i,
.bodyContent .roles2tierRpItems i,
.bodyContent .roles2tierIdpItems i,
.bodyContent .roles2tierAsItems i,
.modalStatus .rolesRpItems i,
.modalStatus .rolesIdpItems i,
.modalStatus .rolesAsItems i,
.modalStatus .rolesProxyItems i,
.modalStatus .roles2tierRpItems i,
.modalStatus .roles2tierIdpItems i,
.modalStatus .roles2tierAsItems i,
.rolesItems i {
  font-size: 1em;
  line-height: 1em;
}
.rolesItems {
  text-align: center;
  color: white;
  padding: 2px 0px;
  margin: 2px;
  display: inline-block;
  font-size: 1em;
  font-weight: normal;
}

.label-any {
  background: #AAAAAA;
  width: 60px;
}

.label-rp {
  background: #f4ce68;
  width: 60px;
}

.label-IdP {
  background: #f36d88;
  width: 60px;
}

.label-AS {
  background: #56d378;
  width: 60px;
}
.label-Proxy {
  background: #48b0f2;
  width: 60px;
}

.label-2nd-TierRP {
  background: #ff955e;
  width: 100px;
}

.label-2nd-TierIdP {
  background: #b17be0;
  width: 100px;
}

.label-2nd-TierAS {
  background: #00be92;
  width: 100px;
}

.bodyContent .tableSummary > thead {
  background: #5d91cc;
  color: #fff;
  text-align: center;
  font-weight: 100;
}
.line-pre {
  white-space: pre-line !important;
}

.bodyContent .tableSummary.table > thead > tr > th {
  border-bottom: 0;
  vertical-align: middle;
  font-size: 1em;
  padding: 10px 4px;
}
.bodyContent .tableSummary > tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}

.bodyContent .tableSummary > tbody > tr > td > a {
  color: #48b0f1;
}
.bodyContent .tableSummary > tbody > tr > td > a:hover,
.bodyContent .tableSummary > tbody > tr > td > a:focus {
  color: #48b0f1;
  text-decoration: none;
}
.swal2-popup {
  font-size: 1.3em !important;
}
.swal2-popup .swal2-styled.swal2-confirm {
  background: #0d5fac !important;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: unset !important;
}
.ant-select-dropdown-menu-item {
  font-size: 1.3em;
  color: #48b0f2;
}
.ant-select-selection-selected-value {
  color: #48b0f2;
}
td {
  word-break: break-all;
  white-space: pre-wrap;
}
@media (min-width: 992px) {
  .bodyContent .col-md-3 {
    width: 28%;
  }
  .bodyContent .col-md-9 {
    width: 72%;
  }
}
