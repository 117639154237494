.news-page .thumbnail {
  width: 100%;
  display: block;
  margin: 0;
  border: 0px;
  padding: 0px;
  border-radius: 0px;
  max-height: 300px;
}
.news-page .ant-select-selection {
  border-radius: 0px;
  border: 1px solid #0d5fac;
}
.news-page .ant-select-selection__placeholder,
.news-page .ant-select-search__field__placeholder {
  color: #48b0f2;
  font-size: 18px;
}
.news-page .ant-select li {
  font-size: 18px;
}
a.chooseCategory:hover,
a.chooseCategory:focus,
a.chooseCategory:active {
  text-decoration: none;
  color: #40a9ff;
}
.chooseCategory {
  margin: 25px 0;
  display: block;
  padding: 10px 15px;
  border: 1px solid #0d5fac;
  width: 250px;
  color: #48b0f2;
  background: #fff;
}
.iconChooseCategory {
  float: right;
  padding-top: 5px;
}
.news-page .news-margin {
  margin-left: -5px !important;
  margin-right: -4px !important;
}
.cardItem {
  min-height: 370px;
  display: inline-block;
  margin: 5px;
  background: #fff;
  min-width: 97%;
  word-break: break-all;
}
.dateFrom {
  width: 100%;
  display: block;
  float: left;
  margin: 7px 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
}

.descCard {
  padding: 0 10px;
  display: block;
  float: left;
  height: 220px;
  width: 100%;
}
.date {
  display: block;
  float: left;
  color: #c61f0c;
  font-weight: normal;
  font-size: 0.8em;
}
.news-detail .date {
  font-size: 0.8em;
}
.from {
  display: block;
  float: right;
  color: #48b0f2;
  font-weight: normal;
  font-size: 0.8em;
}

.cardItem .buttonReadMore {
  background: #48b0f2;
  color: #fff;
  border: 0;
  font-size: 0.8em;
  padding: 4px 0px;
  float: right;
  line-height: 16px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 5px;
  right: 20px;
  width: 90px;
  font-weight: normal;
}
.buttonReadMore:hover,
.buttonReadMore:focus {
  background: #48b0f2;
  color: #fff;
}
.carouselNews {
  width: 100%;
  overflow: hidden;
  margin-bottom: 1em;
  height: 300px;
}
img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.cardItem h4 {
  display: block;
  float: left;
  width: 100%;
  margin: 10px 0;
  color: #26558b;
  font-weight: bold;
  text-align: left;
  line-height: 0.9;
  height: 45px;
}
.cardItem p.news-detail {
  height: 74px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cardItem .news-image {
  height: 200px;
  display: flex;
  justify-content: center;
  justify-items: center;
}
.cardItem p {
  width: 100%;
  display: block;
  float: left;
}
.fqaSearch .form-control {
  margin-left: 10px;
}
.row.select-category {
  padding-left: 5px;
  padding-bottom: 1em;
}
.row.row-offcanvas.row-offcanvas-right,
.row.row-offcanvas.row-offcanvas-right .row {
  margin-right: 0px;
  margin-left: 0px;
}
.cardItem p:not(.dateFrom) {
  color: #000;
  text-align: left;
  line-height: 1.5em;
}
.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.descCard .buttonReadMore:hover,
.descCard .buttonReadMore:focus,
.descCard .buttonReadMore:active,
.descCard .buttonReadMore.active {
  color: #fff;
}
.descCard h3 {
  line-height: 1.2em;
  clear: both;
  font-size: 18px;
}
.ant-carousel .slick-slide img {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.t20 {
  margin-top: 20px;
}

.carouselNews .ant-carousel .slick-dots li.slick-active button {
  background: #48b0f2;
}

.carouselNews .ant-carousel .slick-dots li button {
  width: 24px;
  height: 4px;
  opacity: 1;
}
.carouselNews .arrow {
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}

.carouselNews .slider-frame {
  height: 300px;
}

.color-primary {
  color: #26558b;
}

.news-detail .cover-image .thumbnail {
  height: 100%;
  max-height: unset;
}

.select-category .dd-list-item {
  height: 36px;
}

.select-category .dd-list {
  padding-top: 5px;
}

.select-category .dd-header {
  padding-right: 10px;
}

.select-category ul.dd-list span {
  padding-left: 10px;
  cursor: pointer;
  color: #1890ff;
}

.select-category ul.dd-list span.text-right {
  padding-right: 10px;
  float: right;
  cursor: pointer;
  color: #1890ff;
}
.select-category .btn-category {
  float: right;
}
.select-category .btn-cancel {
  background-color: transparent;
  border: 0;
  color: #1890ff;
  padding: 5px 20px;
}

.select-category .btn-search {
  background-color: #337ab7;
  border: 0;
  color: #ffffff;
  margin-right: 10px;
  padding: 5px 20px;
  border-radius: 3px;
}

.select-category .btn-search:hover,
.select-category .btn-search:active,
.select-category .btn-search:focus,
.select-category .btn-search:visited {
  border: 0;
  outline: unset;
}

.select-category .btn-cancel:hover,
.select-category .btn-cancel:active,
.select-category .btn-cancel:focus,
.select-category .btn-cancel:visited {
  background-color: transparent;
  border: 0;
  color: #1890ff;
  outline: unset;
}
