/* Sidebar */

.mainSidebar {
  width: 25%;
  display: block;
  float: left;
}

.sidebarMenu {
  /*padding: 0px 15px 1.4em 15px; <<old */
  padding: 0px 25px 1.4em 0px;
  width: 100%;
  display: block;
  float: right;
  font-family: "Sarabun";
}

.childSidebarMenu {
  display: inline-block;
  width: 100%;
  padding: 0;
  border-bottom: 1px solid #e7e7e7;
  background: #0d5fac;
  color: #fff;
}

.childSidebarMenu:hover,
.childSidebarMenu.active {
  background: #c61f0c;
}

.linkSidebarMenu {
  text-decoration: none;
  color: #fff;
  width: 100%;
  display: block;
  padding: 13px 15px 16px 25px;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 1em;
}
.linkSidebarMenu:hover,
.linkSidebarMenu:focus,
.linkSidebarMenu:active {
  text-decoration: none;
  color: #fff;
}
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}
@media (max-width: 768px) {
}
.sidebar .icon-bar {
  display: block;
  width: 17px;
  height: 2px;
  border-radius: 6px;
  border: 1px solid #fff;
  margin: 8px -6px 8px -7px;
}

.sidebar .btn.btn-info {
  float: left;
  background-color: #fff;
  border: 1px solid #c6c6c6;
  left: 0;
  margin-left: -33px;
  position: absolute;
  z-index: 890;
  display: none;
}
.sidebar .btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #c6c6c6;
}
.sidebar .btn-info:active:hover,
.sidebar .btn-info.active:hover,
.open > .sidebar .dropdown-toggle.btn-info:hover,
.sidebar .btn-info:active:focus,
.sidebar .btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #c6c6c6;
}
@media (max-width: 991px) {
  .sidebar .btn.btn-info {
    float: left;
    background-color: #0d5fac;
    border: 0;
    left: 0px;
    top: 1px;
    margin: 0;
    position: absolute;
    z-index: 890;
    display: block;
    border-radius: 0px;
    width: 45px;
  }
  .sidebar .icon-bar {
    width: 100%;
  }
  .bodyContent .titleTab {
    padding-left: 55px;
  }
  .sidebar .sidebarMenu {
    padding-left: 0;
    left: 0px;
    width: 45%;
    top: 51px;
    display: block;
    float: right;
    font-family: "Sarabun";
    position: absolute;
    z-index: 880;
  }
}
@media (min-width: 1248px) {
  .sidebar .navbar-nav > li {
    float: left;
  }
}
.sidebar .boxSpan {
}
@media (max-width: 420px) {
  .sidebar .sidebarMenu {
    width: 100%;
    left: 0px;
    padding: 0;
  }
}
@media (max-width: 1199px) {
  .linkSidebarMenu {
    padding: 12px 15px 12px 25px;
  }
  .sidebarMenu {
    padding: 0px 20px 1.4em 0px;
  }
}
