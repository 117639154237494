.faqhead {
  background-color: #26558b;
  color: #fff;
  font-size: 20px;
  padding: 10px 10px;
  margin-bottom: 20px;
}
.searchbtn {
  width: 100px;
}
.faqboxhead.active,
.faqboxhead.active > label.headerinbox,
.faqboxhead.active > label.detailinbox {
  background: #48b0f2;
  color: #fff;
}
.faqboxhead:hover,
.faqboxhead:hover > label.headerinbox,
.faqboxhead:hover > label.detailinbox {
  background: #48b0f2;
  color: #fff;
}

.fqaSearch .ant-input {
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid #48b0f2;
}

.fqaSearch .ant-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: none;
}
.fqaSearch .form-control {
  font-size: 20px;
}
.fqaSearch .btn-search {
  width: 75px;
  background-color: #48b0f2;
  color: #fff;
  border-left: 0px;
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.searchbox {
  overflow: auto;
}
.searchbox .searchrow {
  padding-left: 0px;
}
.searchinput {
  border: 1px solid #48b0f2;
}
.faqchoose {
  display: inline-block;
  margin-left: -10px;
}
.faqchoose .faqchoosebox {
  padding-left: 0 !important;
  margin-left: 10px;
  margin-right: -15px;
}
.faqfunction {
  /* font-family: Kanit; */
}
.faqboxhead {
  text-align: left;
  z-index: 3;
  cursor: pointer;
  height: 120px;
  border: 1px solid #48b0f2;
  padding: 10px 26px;
  margin: 9px 0px;
  background-color: #fff;
  width: 95%;
}
.arrowdown {
  float: right;
}
.headerinbox {
  cursor: pointer;
  width: 100%;
  color: #26558b;
  line-height: normal;
  font-size: 1.1em;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
}
.detailinbox {
  padding-left: 0px !important;
}
.faqchoosebox .checkbox {
  text-align: left;
  z-index: 3;
  cursor: pointer;
  height: 120px;
  border: 1px solid #48b0f2;
  background-color: #48b0f2;
  padding: 10px 26px;
  width: 95%;
  margin: 9px 0;
  /* padding: 20px 15px!important;
  margin: 15px 0px!important; */
}
.checkbox .headerinbox {
  color: #fff !important;
}
.checkbox .detailinbox {
  color: #fff !important;
}
.detailinbox {
  cursor: pointer;
  width: 100%;
  color: #bbb;
  font-size: 1em;
  line-height: 1em;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 2em;
  font-weight: normal;
}
.detailinbox hr {
  color: #bbb;
  height: 1px;
}
.founddatahead {
  color: #26558b;
  font-weight: bold;
}
a .collapsenbox .collapsenhead {
  text-align: left;
  color: #5d91cc !important;
}
a .collapsenbox {
  border: 1px solid #5d91cc !important;
  border-radius: 0 !important;
  background-color: #fff !important;
  background-image: none !important;
}
.checkbox .headerinbox {
  padding-left: 0px !important;
  margin-bottom: 5px !important;
  min-height: 0px !important;
  font-weight: bold;
}
.check .collapsenbox {
  border: 1px solid #5d91cc !important;
  border-radius: 0 !important;
  background-color: #5d91cc !important;
  background-image: none !important;
}
.check .collapsenbox .collapsenhead {
  color: #fff !important;
}
.founddata {
  margin-top: 10px;
}
.linknodatafound {
  font-weight: bold;
  color: #bbb;
}
founddata a,
founddata a:hover,
founddata a:focus {
  text-decoration: none !important;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border: 1px solid #48b0f2;
}

.fqaSearch .ant-btn-primary {
  background-color: #48b0f2;
  border-color: #48b0f2;
}

.fqaSearch .ant-input-search .ant-input-group-addon .ant-input-search-button {
  width: 80px;
  box-shadow: unset;
}

.faq-title-expand .panel-heading {
  background-color: #5d91cc !important;
  border: 1px solid #5d91cc !important;
}

a.faq-title-expand .collapsenbox .collapsenhead {
  color: #ffffff !important;
}

.founddata a,
.founddata a:hover,
.founddata a:focus {
  text-decoration: none !important;
}

.founddata .panel-default {
  border-color: transparent !important;
  box-shadow: unset;
}

.founddata-result {
  margin-top: 0px;
  border-top: 1px solid #4d505d;
}

.founddata a.faqnotfound {
  text-decoration: underline !important;
  color: #0d5fac;
}

.founddata .panel-title {
  font-size: 1.2em;
}

.founddata-result-text {
  color: #26558b;
  font-weight: bold;
  font-size: 1.1em;
}
/*.founddata .panel-collapse{
  border: 1px solid #eeeeee;
}*/
/*
.founddata .panel {
  background-color: transparent;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
}*/
