.uatBlock,
.overviewBlock {
  font-family: "Sarabun";
  color: rgba(0, 0, 0, 1);
  color: #4d505d;
}
.lineBottom {
  margin: 0 0 20px 0;
}
p {
  margin: 0;
}
.testList {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
}

.testDoccumentFile {
  list-style-type: none;
  border: 1px solid #5d91cc;
  margin-bottom: 40px;
  padding: 5px 15px 5px 15px;
  background: #fff;
  margin-top: 15px;
}

.testDoccumentFile i {
  margin-right: 15px;
}

.color1 {
  color: #f36d88;
}

.color2 {
  color: #48b0f1;
}
.color3 {
  color: #000;
}

.overviewBlock {
  font-size: 1em;
  font-variant: tabular-nums;
  list-style: none;
}
.blogSet {
  margin-bottom: 0px;
}

.summaryCardItems {
  background: #fff;
  display: inline-block;
  width: 30%;
  height: auto;
  border: 0;
  margin: 5px 15px 5px 5px;
  font-size: 0.86em;
  float: left;
}
.box-card-center {
  padding-left: 13px;
}
@media (max-width: 725px) {
  .summaryCardItems {
    width: 46%;
  }
}
@media (max-width: 570px) {
  .summaryCardItems {
    width: 100%;
  }
  .summaryCardItems {
    float: none;
  }
  .box-card-center {
    padding-left: 0px;
  }
}
.summaryCardInfo {
  padding: 10px;
  display: block;
  float: left;
  min-height: 300px;
  width: 100%;
}

.summaryCardInfo h5 {
  text-align: center;
  font-size: 1em;
  margin-bottom: 15px;
  color: #26558b;
  font-weight: bold;
  letter-spacing: 0.8px;
}

.summaryCardButton {
  background: white;
  border: 0;
  background: #48b0f2;
  position: absolute;
  color: white;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

.textInfo {
  width: auto;
  float: left;
}

.cardLine {
  width: 100%;
  display: block;
  float: left;
  padding: 5px 0;
  line-height: 0.7em;
}

.cardLine p:not(.textInfo) {
  width: 53px;
  display: block;
  float: left;
}

/*UAT*/

.detailBlock {
  margin-bottom: 40px;
}

.titleDocument {
  margin: 0 0 20px 0;
  border-bottom: 1px solid #26558b;
  font-size: 1.1em;
  color: #26558b;
  font-weight: normal;
  letter-spacing: 0.8px;
  padding-top: 20px;
}
/* .documentItem{
  padding: 0 15px;
} */
.documentItem:hover,
.documentItem:focus {
  color: #48b0f1;
  text-decoration: none;
}

.uatBlock .tableSummary > thead {
  background: #5d91cc;
  color: #fff;
  text-align: center;
  font-weight: 100;
}

.uatBlock .tableSummary.table > thead > tr > th {
  font-weight: normal;
  border-bottom: 0;
  vertical-align: middle;
}
.uatBlock .tableSummary > tbody > tr:nth-of-type(odd) {
  background-color: #fff;
}

.uatBlock .tableSummary > tbody > tr > td > a {
  color: #48b0f1;
}
.uatBlock .tableSummary > tbody > tr > td > a:hover,
.uatBlock .tableSummary > tbody > tr > td > a:focus {
  color: #48b0f1;
  text-decoration: none;
}

.childDocument {
  padding: 5px 0;
}
.btnViewMore {
  /*width: 249px;*/
  width: 100%;
  display: block;
  float: left;
  padding: 10px 15px;
  background: #48b0f1;
  color: #fff;
  text-align: center;
}
.btnViewMore:hover,
.btnViewMore:focus {
  color: #fff;
  text-decoration: none;
}
.addressText {
  margin: 20px 0;
  color: #0d5fac;
}
.addressText p {
  margin: 0;
}
.ant-tabs {
  font-size: 1em !important;
}
.ant-tabs .ant-tabs-ink-bar {
  background-color: #d0021b;
  height: 3px;
}
.ant-tabs .ant-tabs-tab-active {
  color: #0d5fac;
}

.uatBlock .downloadSignoff,
.uatBlock .downloadSignoff:hover,
.uatBlock .downloadSignoff:focus {
  background: #9ab9dc;
  color: #fff;
  cursor: no-drop;
}
.textUploadDate {
  color: #26558b;
  vertical-align: middle;
}

.uatBlock a {
  color: #48b0f2;
}
.trHeaderColor {
  background-color: #5d91cc;
  color: white;
}

.uatBlock .linkI i {
  margin-right: 15px;
}
.summaryCardInfo .pBold {
  font-weight: bold;
  color: #26558b;
}
.summaryCardInfo .row {
  margin-right: 0px;
  margin-left: 0px;
}
.summaryCardInfo .pBold.role-height {
  padding-bottom: 1.8px;
}
@media (max-width: 1199px) {
  .summaryCardInfo .textInfo {
    margin-left: 5px;
  }
}
@media (max-width: 725px) {
  .summaryCardInfo .pBold.role-height {
    padding-bottom: 1.8px;
  }
}

.uatBlock .linka {
  color: #000;
}
.uatBlock .faExternalLink {
  color: #48b0f2;
  float: right;
  margin-right: 15px;
  margin-top: 7px;
  font-size: 1em;
}
.childDocument .fa {
  font-size: 1.2em;
}
